import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ChildMenuProps = {
  UserTypeID: Number;
  MenuID: string;
  Name: string;
  Url: string;
};

export type ParentMenuProps = {
  IsImageIcon: Boolean;
  UserTypeID: Number;
  MenuID: string;
  Name: string;
  Url: string;
  Children: ChildMenuProps[];
};

export type MenuResponseProps = {
  Data: ParentMenuProps[];
};

type InitialStateProps = {
  menuList: MenuResponseProps;
};

const initialState: InitialStateProps = {
  menuList: { Data: [] },
};

const menuSlice = createSlice({
  name: 'menuSlice',
  initialState,
  reducers: {
    add_menu_list: (state, action: PayloadAction<MenuResponseProps>) => {
      state.menuList = action.payload;
    },

    clear_menu_list: (state) => {
      state.menuList = { Data: [] };
    },
  },
});

export default menuSlice.reducer;
export const { add_menu_list, clear_menu_list } = menuSlice.actions;
